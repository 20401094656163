@charset "UTF-8";
/*  Box-sizing settings to include content, padding and border within the width/height and min/max properties.  */
* {
  -webkit-box-sizing: border-box;
  /* Needed for mobile WebKit */
  /* Needed for Firefox */
  box-sizing: border-box;
}
*:before, *:after {
  -webkit-box-sizing: border-box;
  /* Needed for mobile WebKit */
  /* Needed for Firefox */
  box-sizing: border-box;
}

/* *************** COLOR DEFINITIONS *********** */
.bg-grey {
  background: #edeff3;
  background: #fff;
}

.bg-light-grey {
  background: #f3f4f9;
  background: #fff;
}

.bg-bluish-grey {
  background: #e9ebf5;
  background: #fff;
}

/* *************** WEBFONTS **************** */
@font-face {
  font-family: "aileron_light";
  src: url("../webfonts/Aileron-Light.woff2") format("woff2"), url("../webfonts/Aileron-Light.woff") format("woff");
  font-weight: normal;
  font-style: normal;
}
@font-face {
  font-family: "aileron_bold";
  src: url("../webfonts/Aileron-Bold.woff2") format("woff2"), url("../webfonts/Aileron-Bold.woff") format("woff");
  font-weight: normal;
  font-style: normal;
}
@font-face {
  font-family: "resist_sans_regular";
  src: url("../webfonts/Resist-Sans-Regular.woff2") format("woff2"), url("../webfonts/Resist-Sans-Regular.woff") format("woff");
  font-weight: normal;
  font-style: normal;
}
@font-face {
  font-family: "resist_sans_bold";
  src: url("../webfonts/Resist-Sans-Medium.woff2") format("woff2"), url("../webfonts/Resist-Sans-Medium.woff") format("woff");
  font-weight: normal;
  font-style: normal;
}
.ff-al {
  font-family: "aileron_light", "helvetica", "arial", sans-serif;
  letter-spacing: 0.04rem;
}

.ff-ab {
  font-family: "aileron_bold", "helvetica", "arial", sans-serif;
}

.ff-rsr {
  font-family: "resist_sans_regular", "helvetica", "arial", sans-serif;
}

.ff-rsb {
  font-family: "resist_sans_bold", "helvetica", "arial", sans-serif;
}

/*  Subtle fade-in effect for images on load  */
.animate__animated.animate__delay-6s {
  -webkit-animation-delay: 6s;
  animation-delay: 6s;
}

@-webkit-keyframes fadein {
  to {
    opacity: 1;
  }
}
@keyframes fadein {
  to {
    opacity: 1;
  }
}
/* *************** GLOBAL DEFINITIONS *********** */
body {
  font-size: 100%;
  color: #000;
  -moz-osx-font-smoothing: grayscale;
  -webkit-font-smoothing: antialiased;
  font-family: "aileron_light", "helvetica", "arial", sans-serif;
  letter-spacing: 0.04rem;
}

strong {
  font-family: "aileron_bold", "helvetica", "arial", sans-serif;
  font-weight: normal;
}

h1, .h1, h2, .h2, h3, .h3, h4, .h4, h5, .h5, h6, .h6 {
  font-family: "resist_sans_bold", "helvetica", "arial", sans-serif;
  font-weight: normal;
}

p {
  line-height: 1.5;
}
p a {
  color: #000;
  text-decoration: underline;
}

blockquote p {
  font-size: 1.2em;
}

.clear {
  clear: both;
  float: none;
}
.clear:after {
  content: "";
  display: table;
  clear: both;
}
.clear.spacer {
  width: 100%;
  display: block;
  height: 1.5em;
}
.clear.spacer.x2 {
  height: 3em;
}

@media only screen and (min-width: 40em) {
  .clear.spacer {
    width: 100%;
    display: block;
    height: 2em;
  }
}
.spacer {
  display: block;
  width: 100%;
  height: 1.5rem;
}
.spacer.x2 {
  height: 3rem;
}

.no-margin {
  margin: 0 !important;
}

.no-border {
  border: 0 !important;
}

.easing {
  -webkit-transition: all 0.3s ease;
  -o-transition: all 0.3s ease;
  transition: all 0.3s ease;
}

.index.noscroll {
  overflow: hidden;
  height: 100vh;
  position: fixed;
}

.flex-center {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
}

@media print, screen and (max-width: 40em) {
  .no-padding-bottom-mob {
    padding-bottom: 0 !important;
  }
}
@media print, screen and (max-width: 40em) {
  .no-margin-bottom-mob {
    margin-bottom: 0 !important;
  }
}
.no-border {
  border: 0 !important;
}

.hidden {
  display: none;
}

.invisible {
  display: none !important;
}

/* html.sr .scroll-visible  {
    visibility: hidden;
} */
.ani-underline {
  text-decoration: none !important;
  position: relative;
  display: inline-block;
}
.ani-underline span {
  position: relative;
  display: inline-block;
}
.ani-underline span:after {
  content: "";
  height: 1px;
  width: 0;
  background: currentColor;
  position: absolute;
  left: 0;
  bottom: 1px;
  top: auto;
  -webkit-transition: all 0.3s ease;
  -o-transition: all 0.3s ease;
  transition: all 0.3s ease;
}
.ani-underline span:before {
  content: "";
  height: 1px;
  width: 0;
  background: currentColor;
  position: absolute;
  left: 0;
  bottom: 1px;
  top: auto;
  -webkit-transition: all 0.3s ease;
  -o-transition: all 0.3s ease;
  transition: all 0.3s ease;
  width: 100%;
  background: rgba(0, 0, 0, 0.15);
}
.ani-underline.dark span:before {
  background: rgba(255, 255, 255, 0.25);
}

@media print, screen and (min-width: 64em) {
  .ani-underline:hover span:after {
    width: 100%;
  }
}
.animate__animated.animate__delay-7s {
  -webkit-animation-delay: 7s;
  animation-delay: 7s;
}

[data-closeable=false] .close {
  display: none;
}

/* ***************** Main Content ************** */
/* ********************************************** */
/* ****************** SITE NAV ****************** */
/* ********************************************** */
.site-header {
  position: sticky;
  z-index: 998;
  width: 100%;
  left: 0;
  top: 0;
  bottom: auto;
  right: auto;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  transform: none;
  padding: 0.5rem 0.5rem 0.5rem 1.45rem;
  -webkit-box-pack: justify;
  -ms-flex-pack: justify;
  justify-content: space-between;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  background: transparent;
  -webkit-transition: all 0.3s ease;
  -o-transition: all 0.3s ease;
  transition: all 0.3s ease;
}
.site-header--hidden {
  transform: translate(0, -100%);
  transition: transform 0.3s;
}
.site-header.non-home {
  pointer-events: auto;
}
.site-header .logo {
  font-family: "resist_sans_bold", "helvetica", "arial", sans-serif;
  font-size: 1.5rem;
  line-height: normal;
  height: 34px;
  margin: 0;
}
.site-header .logo a {
  text-indent: -9999px;
  display: inline-block;
  width: 104px;
  height: 34px;
  background: url("../images/logo.png") left top no-repeat;
  background-size: 100% auto;
  -webkit-transition: opacity 0.5s ease;
  -o-transition: opacity 0.5s ease;
  transition: opacity 0.5s ease;
  pointer-events: all;
}
.site-header .menu-area {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
}
.site-header .menu-area .hamburger {
  -webkit-transform: scale(0.75);
  -ms-transform: scale(0.75);
  transform: scale(0.75);
}
.site-header .menu-area a {
  color: #000;
  text-decoration: none;
  font-family: "aileron_bold", "helvetica", "arial", sans-serif;
  text-transform: uppercase;
  letter-spacing: 0.04rem;
  font-size: 0.875rem;
  -webkit-transition: all 0.3s ease;
  -o-transition: all 0.3s ease;
  transition: all 0.3s ease;
}
.site-header.is-active {
  opacity: 1;
  pointer-events: auto;
}
.site-header.is-active .logo a {
  opacity: 0;
}
.site-header.is-active .menu-area a {
  color: #fff;
}
.site-header.sticky {
  background: rgba(255, 255, 255, 0.95);
  opacity: 1;
  pointer-events: auto;
  -webkit-transition: all 0.3s ease;
  -o-transition: all 0.3s ease;
  transition: all 0.3s ease;
}

@media print, screen and (min-width: 64em) {
  .site-header .menu-area > *:hover {
    opacity: 0.35;
  }
}
@media print, screen and (min-width: 40em) {
  .site-header {
    padding: 1rem 1rem 1rem 1.6rem;
  }
  .site-header .menu-area .hamburger {
    -webkit-transform: scale(1);
    -ms-transform: scale(1);
    transform: scale(1);
  }
  .site-header .logo {
    font-family: "resist_sans_bold", "helvetica", "arial", sans-serif;
    font-size: 2rem;
  }
}
@media print, screen and (min-width: 64em) {
  .site-header {
    padding: 1.6rem 2rem 1.6rem 2.4rem;
  }
  .site-header .logo {
    height: 42px;
  }
  .site-header .logo a {
    width: 130px;
    height: 42px;
  }
  .site-header .menu-area a {
    padding-top: 0.3rem;
    font-size: 1rem;
  }
}
.site-nav {
  position: fixed;
  overflow: hidden;
  z-index: 997;
  width: 100%;
  height: 0;
  left: 0;
  top: 0;
  bottom: auto;
  right: auto;
  background-color: #000;
  color: #fff;
  display: none;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
}
.site-nav.visibility, .site-nav.slideInDown {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
}
.site-nav.visibility:after, .site-nav.slideInDown:after {
  content: "";
  position: absolute;
  display: block;
  width: 100%;
  height: 100vh;
  background: url(../images/menu_bg.jpg) no-repeat;
  background-size: cover;
  background-position: center top;
  z-index: -1;
  top: 0;
  left: 0;
}
.site-nav.visibility {
  height: 0;
}

.site-nav__inner {
  -webkit-box-flex: 1;
  -ms-flex-positive: 1;
  flex-grow: 1;
}

.site-nav a {
  color: #fff;
}
.site-nav .main-menu ul li {
  -webkit-animation-duration: 0.65s;
  animation-duration: 0.65s;
  list-style-type: none;
  margin: 2.25vw 0;
}
.site-nav .main-menu ul li a {
  font-size: 6vw;
  line-height: 1.3;
  text-transform: uppercase;
  display: block;
  text-decoration: none;
  color: #fff;
  font-family: "resist_sans_bold", "helvetica", "arial", sans-serif;
}
.site-nav .main-menu ul li:nth-child(1) {
  -webkit-animation-delay: 0.7s;
  animation-delay: 0.7s;
}
.site-nav .main-menu ul li:nth-child(2) {
  -webkit-animation-delay: 0.85s;
  animation-delay: 0.85s;
}
.site-nav .main-menu ul li:nth-child(3) {
  -webkit-animation-delay: 1s;
  animation-delay: 1s;
}
.site-nav .main-menu ul li:nth-child(4) {
  -webkit-animation-delay: 1.15s;
  animation-delay: 1.15s;
}
.site-nav .main-menu ul li:nth-child(5) {
  -webkit-animation-delay: 1.3s;
  animation-delay: 1.3s;
}
.site-nav .main-menu ul li:nth-child(6) {
  -webkit-animation-delay: 1.45s;
  animation-delay: 1.45s;
}
.site-nav .contact-details {
  font-size: 15px;
  text-shadow: 0 0 4px rgba(0, 0, 0, 0.85);
}
.site-nav .contact-details a {
  text-decoration: underline;
}
.site-nav .contact-details .location {
  margin: 1rem 0;
}
.site-nav .contact-details .location .name {
  font-family: "aileron_bold", "helvetica", "arial", sans-serif;
  font-size: 14px;
  margin-bottom: 0.25rem;
}
.site-nav .contact-details .animate__animated {
  -webkit-animation-duration: 0.45s;
  animation-duration: 0.45s;
}
.site-nav .contact-details .animate__animated:nth-child(1) {
  -webkit-animation-delay: 0.7s;
  animation-delay: 0.7s;
}
.site-nav .contact-details .animate__animated:nth-child(2) {
  -webkit-animation-delay: 0.85s;
  animation-delay: 0.85s;
}
.site-nav .contact-details .animate__animated:nth-child(3) {
  -webkit-animation-delay: 1.3s;
  animation-delay: 1.3s;
}
.site-nav .contact-details .animate__animated:nth-child(4) {
  -webkit-animation-delay: 1.5s;
  animation-delay: 1.5s;
}
.site-nav .menu-bg-load {
  position: absolute;
}

@media print, screen and (min-width: 40em) {
  .site-nav .main-menu ul li a {
    font-size: 2.5vw;
  }
}
@media print, screen and (min-width: 64em) {
  .site-nav .main-menu ul li a {
    font-size: 3vw;
    -webkit-transition: all 0.3s ease;
    -o-transition: all 0.3s ease;
    transition: all 0.3s ease;
  }
}
@media print, screen and (min-width: 40em) {
  .site-nav .main-menu ul li {
    margin: 0;
  }
}
@media print, screen and (min-width: 64em) {
  .site-nav .main-menu ul:hover li:not(:hover) a {
    opacity: 0.25;
  }
}
.slideInDown {
  -webkit-animation-name: slideInDown;
  animation-name: slideInDown;
  -webkit-animation-duration: 1.25s;
  animation-duration: 1.25s;
  -webkit-animation-timing-function: cubic-bezier(0.9, 0, 0.075, 1);
  animation-timing-function: cubic-bezier(0.9, 0, 0.075, 1);
  -webkit-animation-fill-mode: both;
  animation-fill-mode: both;
}

@-webkit-keyframes slideInDown {
  from {
    height: 0;
    visibility: hidden;
  }
  to {
    height: 100%;
  }
}
@keyframes slideInDown {
  from {
    height: 0;
    visibility: hidden;
  }
  to {
    height: 100%;
  }
}
/* ********************************************** */
/* ***************** LOADER PAGE **************** */
/* ********************************************** */
.loader {
  border: 6px solid #f3f3f3;
  /* Light grey */
  border-top: 6px solid #57b0d4;
  /* Blue */
  border-radius: 50%;
  width: 40px;
  height: 40px;
  -webkit-animation: spin 2s linear infinite;
  animation: spin 2s linear infinite;
}

@-webkit-keyframes spin {
  0% {
    -webkit-transform: rotate(0deg);
    transform: rotate(0deg);
  }
  100% {
    -webkit-transform: rotate(360deg);
    transform: rotate(360deg);
  }
}
@keyframes spin {
  0% {
    -webkit-transform: rotate(0deg);
    transform: rotate(0deg);
  }
  100% {
    -webkit-transform: rotate(360deg);
    transform: rotate(360deg);
  }
}
.pre-loader {
  position: fixed;
  z-index: 1000;
  top: 0;
  left: 0;
  overflow: hidden;
  width: 100%;
  height: 100%;
  background: #fff;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
}
.pre-loader .logo {
  width: 104px;
  height: 34px;
  background: url("../images/logo.png") left top no-repeat;
  background-size: 100% auto;
  margin-bottom: 1rem;
}
.pre-loader .content {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -ms-flex-direction: column;
  flex-direction: column;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
}

/* ********************************************** */
/* ***************** SPLASH PAGE **************** */
/* ********************************************** */
.splash-page {
  position: absolute;
  left: 0;
  top: 0;
  z-index: 2;
  overflow: hidden;
  width: 100%;
  height: 100%;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  background: transparent;
  left: 0;
  top: 0;
  right: auto;
  bottom: auto;
}
.splash-page > * {
  left: 0;
  top: 0;
  right: auto;
  bottom: auto;
}

.splash-page__inner {
  position: absolute;
  width: 100vw;
  height: 100vh;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-transition: opacity 0.5s ease;
  -o-transition: opacity 0.5s ease;
  transition: opacity 0.5s ease;
}

.splash-page__copy-wrapper {
  position: relative;
  -webkit-transition: -webkit-transform 1.6s ease;
  transition: -webkit-transform 1.6s ease;
  -o-transition: transform 1.6s ease;
  transition: transform 1.6s ease;
  transition: transform 1.6s ease, -webkit-transform 1.6s ease;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
}

.splash-page__copy-wrapper__text {
  margin: 0 1.2vw;
  font-family: "resist_sans_bold", "helvetica", "arial", sans-serif;
  color: #fff;
  font-size: 7.5vw;
  line-height: 1.3;
  -webkit-transform: translateY(-50%);
  -ms-transform: translateY(-50%);
  transform: translateY(-50%);
  position: relative;
  display: none;
}
.splash-page__copy-wrapper__text .letter {
  -webkit-transform-origin: 0 100%;
  -ms-transform-origin: 0 100%;
  transform-origin: 0 100%;
  display: inline-block;
}

@media print, screen and (min-width: 40em) {
  .splash-page__copy-wrapper__text {
    font-size: 3.75vw;
    margin: 0 0.4vw;
  }
}
@media print, screen and (min-width: 64em) {
  .splash-page__copy-wrapper__text {
    font-size: 3vw;
  }
}
.slideInLeft {
  -webkit-animation-name: slideInLeft;
  animation-name: slideInLeft;
  -webkit-animation-duration: 0.5s;
  animation-duration: 0.5s;
  -webkit-animation-timing-function: cubic-bezier(0.5, 0, 0, 1);
  animation-timing-function: cubic-bezier(0.5, 0, 0, 1);
  -webkit-animation-fill-mode: both;
  animation-fill-mode: both;
  -webkit-animation-delay: 0;
  animation-delay: 0;
  pointer-events: auto !important;
}

.slideOutRight {
  -webkit-animation-name: slideOutRight;
  animation-name: slideOutRight;
  -webkit-animation-duration: 0.75s;
  animation-duration: 0.75s;
  -webkit-animation-timing-function: cubic-bezier(0.5, 0, 0, 1);
  animation-timing-function: cubic-bezier(0.5, 0, 0, 1);
  -webkit-animation-fill-mode: both;
  animation-fill-mode: both;
  pointer-events: none;
}

.slideOutUp {
  -webkit-animation-name: slideOutUp;
  animation-name: slideOutUp;
  -webkit-animation-delay: 0;
  animation-delay: 0;
  -webkit-animation-duration: 1.25s;
  animation-duration: 1.25s;
  -webkit-animation-timing-function: cubic-bezier(0.9, 0, 0.075, 1);
  animation-timing-function: cubic-bezier(0.9, 0, 0.075, 1);
  -webkit-animation-fill-mode: both;
  animation-fill-mode: both;
}

.fadeIn {
  -webkit-animation-name: fadeIn;
  animation-name: fadeIn;
  -webkit-animation-delay: 0s;
  animation-delay: 0s;
  -webkit-animation-duration: 2s;
  animation-duration: 2s;
  -webkit-animation-fill-mode: both;
  animation-fill-mode: both;
  opacity: 0;
}

.fadeIn-delayed {
  -webkit-animation-name: fadeIn;
  animation-name: fadeIn;
  -webkit-animation-delay: 1s;
  animation-delay: 1s;
  -webkit-animation-duration: 2s;
  animation-duration: 2s;
  -webkit-animation-fill-mode: both;
  animation-fill-mode: both;
  opacity: 0;
}

.fadeOut {
  -webkit-animation-name: fadeOut;
  animation-name: fadeOut;
  -webkit-animation-duration: 0.25s;
  animation-duration: 0.25s;
  -webkit-animation-fill-mode: both;
  animation-fill-mode: both;
}
.fadeOut:nth-child(3) {
  -webkit-animation-delay: 2.75s;
  animation-delay: 2.75s;
}
.fadeOut:nth-child(2) {
  -webkit-animation-delay: 3s;
  animation-delay: 3s;
}
.fadeOut:nth-child(1) {
  -webkit-animation-delay: 3.25s;
  animation-delay: 3.25s;
}

@-webkit-keyframes slideInLeft {
  from {
    -webkit-transform: translateX(20%);
    transform: translateX(20%);
    opacity: 0;
  }
  to {
    -webkit-transform: translateX(0);
    transform: translateX(0);
    opacity: 1;
  }
}
@keyframes slideInLeft {
  from {
    -webkit-transform: translateX(20%);
    transform: translateX(20%);
    opacity: 0;
  }
  to {
    -webkit-transform: translateX(0);
    transform: translateX(0);
    opacity: 1;
  }
}
@-webkit-keyframes slideOutRight {
  from {
    -webkit-transform: translateX(0);
    transform: translateX(0);
    opacity: 1;
  }
  to {
    -webkit-transform: translateX(0%);
    transform: translateX(0%);
    opacity: 0;
  }
}
@keyframes slideOutRight {
  from {
    -webkit-transform: translateX(0);
    transform: translateX(0);
    opacity: 1;
  }
  to {
    -webkit-transform: translateX(0%);
    transform: translateX(0%);
    opacity: 0;
  }
}
@-webkit-keyframes slideOutUp {
  from {
    opacity: 100;
  }
  to {
    visibility: hidden;
    opacity: 0;
  }
}
@keyframes slideOutUp {
  from {
    opacity: 100;
  }
  to {
    visibility: hidden;
    opacity: 0;
  }
}
@-webkit-keyframes fadeOut {
  from {
    opacity: 100;
  }
  to {
    opacity: 0;
  }
}
@keyframes fadeOut {
  from {
    opacity: 100;
  }
  to {
    opacity: 0;
  }
}
@-webkit-keyframes fadeIn {
  from {
    opacity: 0;
  }
  to {
    opacity: 100;
  }
}
@keyframes fadeIn {
  from {
    opacity: 0;
  }
  to {
    opacity: 100;
  }
}
/* ********************************************** */
/* ***************** LANDING PAGE **************** */
/* ********************************************** */
.landing-page {
  width: 100%;
  height: 101vh;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  color: #000;
  position: relative;
  overflow: hidden;
  background: #fff;
  padding: 0 6%;
}

@media print, screen and (min-width: 40em) {
  .landing-page {
    padding: 0 10%;
  }
}
.landing-page__text {
  -webkit-transition: opacity 0.5s ease;
  -o-transition: opacity 0.5s ease;
  transition: opacity 0.5s ease;
  text-align: center;
  font-family: "resist_sans_regular", "helvetica", "arial", sans-serif;
  font-weight: normal;
  font-size: 5.55vw;
  line-height: 1.3;
  color: #fff;
  z-index: 2;
  opacity: 0;
}
.landing-page__text strong {
  font-family: "resist_sans_bold", "helvetica", "arial", sans-serif;
  font-weight: normal;
}

@media print, screen and (min-width: 40em) {
  .landing-page__text {
    font-size: 3.75vw;
  }
}
@media print, screen and (min-width: 64em) {
  .landing-page__text {
    font-size: 3vw;
  }
}
.landing-page #scroll_down_container {
  position: absolute;
  cursor: pointer;
  width: 72px;
  height: 72px;
  display: block;
  opacity: 0;
  bottom: 112px;
  left: 50%;
  margin-left: -36px;
  z-index: 22;
}
.landing-page #scroll_down_container .chevron {
  position: absolute;
  display: block;
  width: 24px;
  height: 4px;
  opacity: 0;
  left: 24px;
  top: 6px;
  -webkit-animation: move 3s ease-out infinite;
  animation: move 3s ease-out infinite;
}
.landing-page #scroll_down_container .chevron:before, .landing-page #scroll_down_container .chevron:after {
  content: " ";
  position: absolute;
  display: block;
  top: 0;
  height: 100%;
  width: 51%;
  background: #fff;
}
.landing-page #scroll_down_container .chevron:before {
  left: 0;
  -webkit-transform: skew(0deg, 45deg);
  -ms-transform: skew(0deg, 45deg);
  transform: skew(0deg, 45deg);
}
.landing-page #scroll_down_container .chevron:after {
  right: 0;
  width: 50%;
  -webkit-transform: skew(0deg, -45deg);
  -ms-transform: skew(0deg, -45deg);
  transform: skew(0deg, -45deg);
}
.landing-page #scroll_down_container .chevron:first-child {
  -webkit-animation: move 2s ease-out 1s infinite;
  animation: move 2s ease-out 1s infinite;
}
.landing-page #scroll_down_container .chevron.chevron:nth-child(2) {
  -webkit-animation: move 2s ease-out 2s infinite;
  animation: move 2s ease-out 2s infinite;
}

@-webkit-keyframes move {
  25% {
    opacity: 0.5;
    -webkit-transform: translateY(10px);
    transform: translateY(10px);
  }
  50% {
    opacity: 1;
    -webkit-transform: translateY(15px);
    transform: translateY(15px);
  }
  75% {
    opacity: 0.5;
    -webkit-transform: translateY(20px);
    transform: translateY(20px);
  }
  100% {
    opacity: 0;
    -webkit-transform: translateY(25px);
    transform: translateY(25px);
  }
}
@keyframes move {
  25% {
    opacity: 0.5;
    -webkit-transform: translateY(10px);
    transform: translateY(10px);
  }
  50% {
    opacity: 1;
    -webkit-transform: translateY(15px);
    transform: translateY(15px);
  }
  75% {
    opacity: 0.5;
    -webkit-transform: translateY(20px);
    transform: translateY(20px);
  }
  100% {
    opacity: 0;
    -webkit-transform: translateY(25px);
    transform: translateY(25px);
  }
}
.cta-btn {
  display: inline-block;
  position: relative;
  background: #57b0d4;
  color: #fff;
  border: none;
  cursor: pointer;
  font-family: "aileron_bold", "helvetica", "arial", sans-serif;
  font-size: 0.75rem;
  padding: 0.75rem 1.325rem;
  margin-top: 1rem;
}
.cta-btn:hover {
  color: #fff;
}

.section-content {
  padding: 4rem 0;
  min-height: 90vh;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  position: relative;
  border-top: 1px solid #dcdee5;
}
.section-content#contact {
  min-height: 0;
  flex-direction: column;
  justify-content: space-between;
}
.section-content.webform {
  border-top: 0 none;
  opacity: 0;
  animation: show 0.6s ease-in-out 2s 1 forwards;
}
.section-content .close {
  width: 20px;
  height: 20px;
  -webkit-transform: rotate(45deg);
  -ms-transform: rotate(45deg);
  transform: rotate(45deg);
  position: absolute;
  cursor: pointer;
  text-indent: -9999px;
  top: 0;
  right: 1rem;
}
.section-content .close:before {
  content: "";
  display: block;
  height: 1px;
  width: 100%;
  background: #000;
  position: absolute;
  top: 50%;
  left: 0;
}
.section-content .close:after {
  content: "";
  display: block;
  height: 1px;
  width: 100%;
  background: #000;
  position: absolute;
  top: 50%;
  left: 0;
  -webkit-transform: rotate(90deg);
  -ms-transform: rotate(90deg);
  transform: rotate(90deg);
}
.section-content .close.top0 {
  top: 0;
}
.section-content .back {
  display: inline-block;
  text-transform: uppercase;
  margin-bottom: 1em;
  font-family: "aileron_bold", "helvetica", "arial", sans-serif;
}
.section-content .back:before {
  content: "";
  font-family: "Font Awesome 5 Free";
  font-weight: 900;
  margin-right: 0.25em;
}
.section-content .title {
  margin-bottom: 1rem;
}
.section-content .section-content-inner {
  width: 100%;
  position: relative;
}
.section-content .service-wrapper {
  border: 0;
  border-bottom: 1px solid #dcdee5;
  margin: 0 0.5rem;
  padding: 2rem 0;
}
.section-content .service-wrapper .desc.big {
  font-size: 1.125em;
  margin-bottom: 0;
}
.section-content .service-wrapper .desc.big p {
  margin-bottom: 1.25em;
}
.section-content .service-wrapper .desc.big ul {
  font-size: 1rem;
}
.section-content .service:last-of-type .service-wrapper {
  border: 0;
}
.section-content .partner-logos img {
  border: 1px solid #dcdee5;
  /*
            @media print, screen and (min-width: 64em) {
                filter: grayscale(100%);
                .easing;

                &:hover {
                    filter: grayscale(0%);
                }
            }*/
}
.section-content .partner-logos > .grid-x {
  padding: 0 0.5rem;
}
.section-content .contact-page {
  padding: 0 0.5rem;
}
.section-content .contact-page .logo {
  max-width: 128px;
  margin-bottom: 1.5rem;
}
.section-content .contact-page .quick-links {
  font-size: 14px;
  line-height: 1.5;
  font-family: "aileron_bold", "helvetica", "arial", sans-serif;
}
.section-content .contact-page .quick-links ul {
  padding: 0;
  margin: 0;
}
.section-content .contact-page .quick-links li {
  padding: 0;
  margin: 0;
  list-style-type: none;
}
.section-content .contact-page .quick-links a {
  padding: 0.5rem 0;
}
.section-content .contact-page .desc.big {
  font-size: 1.125em;
  margin-bottom: 0;
}
.section-content .contact-page .desc.big p {
  margin-bottom: 1.5em;
}
.section-content#contact {
  padding: 4rem 0 0;
  margin-top: -1vh;
  border-top: 0 none;
  overflow: hidden;
}
.section-content#contact .section-content-inner {
  z-index: 2;
}
.section-content#contact .contact-wrap {
  padding-bottom: 4rem;
}
.section-content#contact .terms {
  width: 100%;
  background: rgba(255, 255, 255, 0.45);
  padding: 0;
  font-size: 0.875rem;
  z-index: 3;
  padding-top: 1rem;
  padding-bottom: 1rem;
}
.section-content#privacy {
  border-top: 0 none;
}
.section-content#privacy h1.first {
  margin-top: 2em;
}

@media print, screen and (min-width: 40em) {
  .section-content .close {
    width: 30px;
    height: 30px;
    top: 1rem;
    right: 2rem;
  }
  .section-content .back {
    margin-bottom: 1em;
  }
  .section-content .title {
    margin-left: -1px;
  }
  .section-content .content-wrapper {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
  }
  .section-content .content-wrapper.tall .service {
    border: 1px solid #dcdee5;
    border-width: 0 1px;
    margin-right: -1px;
  }
  .section-content .content-wrapper.tall .service:first-of-type {
    border-left: 0;
  }
  .section-content .content-wrapper.tall .service:last-of-type {
    border-right: 0;
  }
  .section-content .service-wrapper {
    border: 0;
    padding: 0 0.5rem;
    margin: 0;
  }
  .section-content .service-wrapper .desc.big {
    margin-bottom: 1em;
  }
  .section-content .contact-page .logo {
    max-width: 148px;
    -webkit-transform: translateY(-160%);
    -ms-transform: translateY(-160%);
    transform: translateY(-160%);
    position: absolute;
    top: 0;
    left: auto;
  }
}
@media print, screen and (min-width: 40em) {
  .section-content .content-wrapper.tall {
    -webkit-box-align: stretch;
    -ms-flex-align: stretch;
    align-items: stretch;
  }
  .section-content .content-wrapper.tall .service {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    margin-bottom: 2rem;
  }
  .section-content .content-wrapper.tall .service .service-wrapper {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -ms-flex-direction: column;
    flex-direction: column;
  }
  .section-content .content-wrapper.tall .service .service-wrapper .content {
    -webkit-box-flex: 1;
    -ms-flex-positive: 1;
    flex-grow: 1;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: start;
    -ms-flex-align: start;
    align-items: flex-start;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -ms-flex-direction: column;
    flex-direction: column;
  }
  .section-content .content-wrapper.tall .service .service-wrapper .desc {
    -webkit-box-flex: 1;
    -ms-flex-positive: 1;
    flex-grow: 1;
  }
}
@media print, screen and (min-width: 64em) {
  .section-content .close {
    top: 1.85rem;
    right: 2rem;
  }
  .section-content .back {
    margin-bottom: 1em;
  }
  .section-content .back:before {
    margin-right: 0.75em;
    -webkit-transition: all 0.3s ease;
    -o-transition: all 0.3s ease;
    transition: all 0.3s ease;
  }
  .section-content .back:hover:before {
    margin-right: 0.25em;
  }
  .section-content .service-wrapper {
    padding: 1.85rem 1.5rem;
  }
  .section-content .content-wrapper.tall .service {
    margin-bottom: 0;
  }
  .section-content .partner-logos > div {
    padding: 0 1.5rem;
  }
  .section-content .contact-page {
    padding-left: 1.5rem;
  }
}
.video-container {
  position: absolute;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  z-index: 0;
  width: 100%;
  height: 100%;
  background: url("../images/home_bg.jpg") center top no-repeat;
  background-size: cover;
}
.video-container.contact {
  background: url("../images/contact_bg.jpg") center top no-repeat;
  background-size: cover;
}
.video-container video {
  -o-object-fit: cover;
  object-fit: cover;
  width: 100%;
  height: 100%;
}

.video-responsive {
  position: relative;
  display: block;
  overflow: hidden;
  margin-bottom: 2rem;
}
@media screen and (min-width: 64em) {
  .video-responsive {
    margin: 0;
  }
}
.video-responsive::before {
  display: block;
  content: "";
  padding-top: 56.25%;
}
.video-responsive__item {
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  width: 100%;
  height: 100%;
  border: 0;
}

.overlay {
  position: absolute;
  top: 0;
  left: 0;
  height: 100%;
  width: 100%;
  background-color: rgba(0, 0, 0, 0.35);
  z-index: 1;
}
.overlay.white {
  background-color: rgba(255, 255, 255, 0.5);
}

.contact-details {
  font-size: 15px;
  line-height: 1.5;
  margin-top: 2rem;
}
.contact-details .big {
  font-size: 1.125em;
  margin-top: 1em;
}
.contact-details a {
  text-decoration: underline;
}
.contact-details a.big-bold {
  font-size: 1.13em;
  font-family: "aileron_bold", "helvetica", "arial", sans-serif;
}
.contact-details .location {
  margin: 0.5rem 0 2rem;
}
.contact-details .location .name {
  font-family: "aileron_bold", "helvetica", "arial", sans-serif;
  font-size: 14px;
  margin-bottom: 0.75rem;
}
.contact-details .location .tel {
  font-family: "aileron_bold", "helvetica", "arial", sans-serif;
  margin-top: 0.25rem;
}

@media print, screen and (min-width: 64em) {
  .contact-details {
    margin-top: 0;
  }
}
.multi-level-wrapper {
  position: relative;
  overflow-x: hidden;
}
.multi-level-wrapper #identity-access-management {
  -webkit-transition: all 0.3s ease;
  -o-transition: all 0.3s ease;
  transition: all 0.3s ease;
}

@media print, screen and (min-width: 64em) {
  .multi-level-wrapper {
    overflow: hidden;
  }
}
.reveal-right {
  position: absolute;
  left: 0;
  top: 0;
  z-index: 100;
  background: #fff;
  width: 100%;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-transform: translateX(20%);
  -ms-transform: translateX(20%);
  transform: translateX(20%);
  min-height: 100vh;
  -webkit-box-align: start;
  -ms-flex-align: start;
  align-items: flex-start;
  overflow: auto;
  padding-bottom: 2rem;
  opacity: 0;
  pointer-events: none;
}

@media print, screen and (min-width: 40em) {
  .reveal-right {
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    padding-bottom: 4rem;
  }
}
.locked-page {
  z-index: 10;
}

@media print, screen and (max-width: 39.99875em) {
  .hide-mobile-initially {
    display: none;
  }
  .hide-mobile-initially.locked-page {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
  }
}
.team-member {
  padding-top: 1rem;
}

@media print, screen and (min-width: 40em) {
  .team-member {
    padding-top: 1rem;
    padding-bottom: 1rem;
  }
}
@media print, screen and (min-width: 64em) {
  .team-member {
    padding-top: 2rem;
    padding-bottom: 2rem;
  }
}
.fsBody .fsForm {
  margin: 0 auto !important;
  padding: 10px 20px !important;
}

/*# sourceMappingURL=maps/styles.css.map */
body.index .site-header {
  position: fixed;
}

@keyframes show {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}
.sitemap {
  padding-left: 0;
}
@media only screen and (min-width: 64em) {
  .sitemap {
    display: flex;
    justify-content: space-evenly;
    flex-wrap: wrap;
  }
}
.sitemap__col {
  padding: 0 1rem 0 0;
  margin: 0;
}
.sitemap li {
  list-style: none;
}

.copy {
  margin-top: 1rem;
}

.centre-img {
  display: flex;
  flex-direction: column;
  justify-content: center;
  margin-top: 3rem;
}
@media only screen and (min-width: 64em) {
  .centre-img {
    margin: 0;
  }
}
